<template>
  <div class="mb-10">
    <v-form ref="dataImportForm" v-model="isFormValid" class="multi-col-validation" @submit.prevent="onSubmit">
      <v-row>
        <v-col cols="12" md="12">
          <v-label>Please select how the company employees will be imported into the portal</v-label>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            v-model="dataImportProcess"
            :items="dataImportOptions"
            single-line
            outlined
            dense
            hide-details
            class="m-0"
            :rules="[validators.required]"
          ></v-select>
        </v-col>

        <template v-if="dataImportProcess === DATA_IMPORT_PERSONIO">
          <v-col cols="12" md="12">
            <v-label>
              <h3 class="mb-4 mt-5">Personio Credentials</h3>
              <div class="multiline-label">
                The following Personio Credentials will be provided by the company so that we can access their Personio
                data. Please note that the following credentials should be kept confidential.
              </div>
            </v-label>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              v-if="!companyHasValidPersonioCreds"
              v-model="personioID"
              type="password"
              outlined
              dense
              hide-details
              full-width
              placeholder="Personio ID"
              class="mb-4"
            ></v-text-field>
            <v-text-field
              v-if="!companyHasValidPersonioCreds"
              v-model="personioSecret"
              type="password"
              outlined
              dense
              hide-details
              full-width
              placeholder="Personio secret"
              class="mb-4"
            ></v-text-field>
            <div class="d-flex">
              <v-btn outlined class="flex-button mr-2" @click="resetPersonioCredentials"> Reset </v-btn>
              <v-btn
                v-if="!companyHasValidPersonioCreds"
                color="primary"
                class="flex-button"
                @click="setPersonioCredentials"
              >
                Save credentials
              </v-btn>
            </div>
          </v-col>

          <template v-if="companyIsPersonioConnected && companyHasValidPersonioCreds">
            <v-col cols="12" md="12">
              <v-label>
                <h3 class="mb-4 mt-5">Field Mapping</h3>
                <div class="multiline-label">
                  Please set the corresponding values for the following fields. Please note that it is necessary to
                  speak to the company to determine the exact field names to enter.
                </div>
              </v-label>
            </v-col>
            <v-col cols="12" md="12" class="d-flex justify-end pt-0">
              <v-btn color="primary" @click="addFieldMapping"> Add field mapping </v-btn>
            </v-col>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="fieldMappingHeaders"
                :items="fieldMappings"
                :hide-default-footer="true"
                class="text-no-wrap v-data-table--roles"
              >
                <template #[`item.deguraField`]="{ item }">
                  <v-text-field
                    v-model="item.deguraField"
                    outlined
                    dense
                    hide-details
                    placeholder="Degura field"
                    class="my-4"
                    :rules="[validators.required]"
                  ></v-text-field>
                </template>

                <template #[`item.mapToField`]="{ item }">
                  <v-select
                    v-model="item.mapToField"
                    :items="attributes"
                    outlined
                    dense
                    hide-details
                    placeholder="Map to company field"
                    class="my-4"
                    :rules="[validators.required]"
                  ></v-select>
                </template>

                <!-- actions -->
                <template #[`item.actions`]="{ item }">
                  <div class="d-flex align-center justify-center">
                    <!-- remove field mapping-->
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" @click="removeFieldMapping(item)" v-on="on">
                          <v-icon size="18">
                            {{ icons.mdiMinusCircle }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Remove</span>
                    </v-tooltip>
                  </div>
                </template>

                <template slot="no-data">
                  <div class="my-5">
                    <p>No field mapping added yet</p>
                  </div>
                </template>
              </v-data-table>
            </v-col>

            <v-col cols="12" md="12">
              <v-label>
                <h3 class="mb-4 mt-5">Value Mapping</h3>
                <div class="multiline-label">Please specify the values that should be mapped to the fields below.</div>
              </v-label>
            </v-col>
            <v-col cols="12" md="12" class="d-flex justify-end pt-0">
              <v-btn color="primary" @click="addValueMapping"> Add value mapping </v-btn>
            </v-col>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="valueMappingHeaders"
                :items="valueMappings"
                :hide-default-footer="true"
                class="text-no-wrap v-data-table--roles"
              >
                <template #[`item.deguraFieldValue`]="{ item }">
                  <v-text-field
                    v-model="item.deguraFieldValue"
                    outlined
                    dense
                    hide-details
                    placeholder="Degura field-value(s)"
                    class="my-4"
                    :rules="[validators.required]"
                  ></v-text-field>
                </template>
                <template #[`item.mapToField`]="{ item }">
                  <v-text-field
                    v-model="item.mapToField"
                    outlined
                    dense
                    hide-details
                    placeholder="Company field"
                    class="my-4"
                  ></v-text-field>
                </template>
                <template #[`item.mapToFieldValue`]="{ item }">
                  <v-text-field
                    v-model="item.mapToFieldValue"
                    outlined
                    dense
                    hide-details
                    placeholder="Map to company field-value(s)"
                    class="my-4"
                    :rules="[validators.required]"
                  ></v-text-field>
                </template>

                <!-- actions -->
                <template #[`item.actions`]="{ item }">
                  <div class="d-flex align-center justify-center">
                    <!-- remove value mapping-->
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" @click="removeValueMapping(item)" v-on="on">
                          <v-icon size="18">
                            {{ icons.mdiMinusCircle }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Remove</span>
                    </v-tooltip>
                  </div>
                </template>

                <template slot="no-data">
                  <div class="my-5">
                    <p>No value mapping added yet</p>
                  </div>
                </template>
              </v-data-table>
            </v-col>

            <v-col cols="12" md="12">
              <v-label>
                <h3 class="mb-4 mt-5">Filter Selection</h3>
                <div class="multiline-label">
                  Please specify all of the filters that the company uses below. These filters are the ones we need so
                  that we can import employees. Please make sure that the correct corresponding values are set.
                </div>
              </v-label>
            </v-col>
            <v-col cols="12" md="12" class="d-flex justify-end pt-0">
              <v-btn color="primary" outlined class="mr-5" @click="resetFilters"> Reset </v-btn>
              <v-btn color="primary" @click="addFilter"> Add filter </v-btn>
            </v-col>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="filterHeaders"
                :items="filters"
                :hide-default-footer="true"
                class="text-no-wrap v-data-table--roles"
              >
                <template #[`item.companyField`]="{ item }">
                  <v-select
                    v-model="item.companyField"
                    :items="attributes"
                    outlined
                    dense
                    hide-details
                    placeholder="Filter of company"
                    class="my-4"
                    :rules="[validators.required]"
                  ></v-select>
                </template>
                <template #[`item.logicOperator`]="{ item }">
                  <v-select
                    v-model="item.logicOperator"
                    :items="logicOperatorOptions"
                    single-line
                    outlined
                    dense
                    hide-details
                    placeholder="Logic"
                    class="my-4"
                    :rules="[validators.required]"
                  ></v-select>
                </template>
                <template #[`item.filterValue`]="{ item }">
                  <v-text-field
                    v-model="item.filterValue"
                    outlined
                    dense
                    hide-details
                    placeholder="Value"
                    class="my-4"
                    :rules="[validators.required]"
                  ></v-text-field>
                </template>

                <!-- actions -->
                <template #[`item.actions`]="{ item }">
                  <div class="d-flex align-center justify-center">
                    <!-- remove filter-->
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" @click="removeFilter(item)" v-on="on">
                          <v-icon size="18">
                            {{ icons.mdiMinusCircle }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Remove</span>
                    </v-tooltip>
                  </div>
                </template>

                <template slot="no-data">
                  <div class="my-5">
                    <p>No filters added yet</p>
                  </div>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" md="12" class="d-flex pt-0">
              <v-btn color="primary" @click="dryRun"> Preview </v-btn>
            </v-col>
            <v-col cols="12" md="12">
              <div class="previewPlaceholder">
                <template v-if="!isDryRunning && dryRunResults.length === 0">
                  Preview results will be displayed here...
                </template>
                <template v-if="isDryRunning">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </template>
                <template v-if="dryRunResults.length > 0">
                  <div>Added employees: {{ dryRunResults[0] }}</div>
                  <div>Updated employees: {{ dryRunResults[1] }}</div>
                  <div>Deleted employees: {{ dryRunResults[2] }}</div>
                </template>
              </div>
            </v-col>
            <v-col cols="12" md="12">
              <v-label>
                <h3 class="mb-4 mt-5">Schedule Synchronization</h3>
                <div class="multiline-label">
                  Once everything looks good, please click on the Synchronize button below so that the Personio
                  Connector can be added to the automatic synchronization process.
                </div>
              </v-label>
            </v-col>
            <v-col cols="12" md="12" class="d-flex pt-5 pb-5">
              <v-btn color="primary" @click="onSync">
                <template v-if="!isSyncRunning">Sync</template>
                <template v-if="isSyncRunning">
                  <v-progress-circular :size="18" :width="2" indeterminate color="white"></v-progress-circular>
                </template>
              </v-btn>
            </v-col>
          </template>
        </template>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { ref, getCurrentInstance, computed } from '@vue/composition-api';
import { mdiMinusCircle } from '@mdi/js';

import { required } from '@core/utils/validation';

import {
  getPersonioCompanyById,
  getPersonioAttributes,
  postPersonioSyncDry,
  postValidateCredentials,
  postPersonioSync,
  postConnectPersonio,
  putUpdatePersonio,
  putResetPersonioCredentials,
} from '@/api/personio-connector';

import { DATA_IMPORT_MANUAL, DATA_IMPORT_PERSONIO } from '@/constants';

export default {
  components: {},
  props: ['companyData'],
  setup(props) {
    const vm = getCurrentInstance().proxy;

    const dataImportForm = ref(null);
    const isFormValid = ref(false);
    const companyIsPersonioConnected = ref(false);
    const companyHasValidPersonioCreds = ref(false);
    const attributes = ref([]);

    const isDryRunning = ref(false);
    const dryRunResults = ref([]);
    const isSyncRunning = ref(false);

    const personioID = ref('');
    const personioSecret = ref('');

    const setPersonioCredentials = async () => {
      if (companyIsPersonioConnected.value) {
        await updatePersonioCredentials();
      } else {
        await connectPersonio();
      }
    };
    const resetPersonioCredentials = () => {
      putResetPersonioCredentials(props.companyData.id).then((res) => {
        vm.$store.commit('showMessage', {
          text: 'Success: Personio credentials reset successfully.',
          color: 'success',
          timeout: '10000',
        });
        companyHasValidPersonioCreds.value = false;
        personioID.value = '';
        personioSecret.value = '';
      });
    };

    const dryRun = async () => {
      const isValid = dataImportForm.value.validate();
      if (!isValid) {
        vm.$store.commit('showMessage', {
          text: 'Error: Please correct any errors in the form',
          color: 'error',
          timeout: '10000',
        });
        return;
      }
      isDryRunning.value = true;

      dryRunResults.value = [];
      await updatePersonioConfig()
        .then(() => {
          postPersonioSyncDry(vm.$route.params.id).then((res) => {
            dryRunResults.value = res.data.log.match(/\d+/g);
            isDryRunning.value = false;
          });
        })
        .catch((err) => {
          isDryRunning.value = false;
        });
    };

    const connectPersonio = async () => {
      companyIsPersonioConnected.value = false;
      companyHasValidPersonioCreds.value = false;

      const credentialsValid = await postValidateCredentials({
        clientId: personioID.value,
        clientSecret: personioSecret.value,
      });
      if (!credentialsValid) {
        vm.$store.commit('showMessage', {
          text: 'Error: Personio credentials are invalid',
          color: 'error',
          timeout: '10000',
        });
        return; // implicit else
      }

      const payload = {
        portalCompanyId: props.companyData.id,
        name: props.companyData.companyName,
        filters: [],
        targetToSourceFieldMapping: {},
        fieldEnumMapping: {},
        clientId: personioID.value,
        clientSecret: personioSecret.value,
        schedule: '* * * ? * * 2021',
      };

      postConnectPersonio(payload).then(() => {
        companyIsPersonioConnected.value = true;
        companyHasValidPersonioCreds.value = true;
        getDataFromPersonio();
      });
    };

    const updatePersonioCredentials = async () => {
      const credentialsValid = await postValidateCredentials({
        clientId: personioID.value,
        clientSecret: personioSecret.value,
      });
      if (!credentialsValid) {
        vm.$store.commit('showMessage', {
          text: 'Error: Personio credentials are invalid',
          color: 'error',
          timeout: '10000',
        });
        return;
      }

      const payload = {
        portalCompanyId: props.companyData.id,
        clientId: personioID.value,
        clientSecret: personioSecret.value,
      };

      putUpdatePersonio(props.companyData.id, payload).then(() => {
        companyHasValidPersonioCreds.value = true;
        vm.$store.commit('showMessage', {
          text: 'Success: Personio credentials updated and valid',
          color: 'success',
          timeout: '10000',
        });
        getDataFromPersonio();
      });
    };

    const onSync = async () => {
      isSyncRunning.value = true;
      updatePersonioConfig()
        .then(() => {
          syncPersonio();
        })
        .catch((err) => {
          isSyncRunning.value = false;
        });
    };

    const updatePersonioConfig = async () => {
      const isValid = dataImportForm.value.validate();
      if (!isValid) {
        vm.$store.commit('showMessage', {
          text: 'Error: Please correct any errors in the form',
          color: 'error',
          timeout: '10000',
        });
        throw Error(); // Don't sync personio
      }

      const fieldMappingsToSend = {};
      for (const fm of fieldMappings.value) {
        fieldMappingsToSend[fm.deguraField] = fm.mapToField;
      }

      const valueMappingsToSend = {};
      for (const vm of valueMappings.value) {
        if (!(vm.deguraFieldValue in valueMappingsToSend)) valueMappingsToSend[vm.deguraFieldValue] = {};
        valueMappingsToSend[vm.deguraFieldValue][vm.mapToField] = vm.mapToFieldValue;
      }

      const filtersToSend = filters.value.map((filter) => ({
        field: filter.companyField,
        operation: filter.logicOperator,
        value: filter.filterValue,
      }));

      const payload = {
        portalCompanyId: props.companyData.id,
        filters: filtersToSend,
        targetToSourceFieldMapping: fieldMappingsToSend,
        fieldEnumMapping: valueMappingsToSend,
      };

      await putUpdatePersonio(props.companyData.id, payload);
    };

    const syncPersonio = () => {
      postPersonioSync(props.companyData.id)
        .then(() => {
          isSyncRunning.value = false;
          vm.$store.commit('showMessage', {
            text: 'Success: Company synced',
            color: 'success',
            timeout: '10000',
          });
        })
        .catch((err) => {
          isSyncRunning.value = false;
        });
    };

    const dataImportProcess = ref(DATA_IMPORT_MANUAL);
    const dataImportOptions = [
      {
        text: 'Manual',
        value: DATA_IMPORT_MANUAL,
      },
      {
        text: 'Personio',
        value: DATA_IMPORT_PERSONIO,
      },
    ];

    const fieldMappings = ref([]);
    const addFieldMapping = () => {
      fieldMappings.value.push({
        id: fieldMappings.value.length,
        deguraField: '',
        mapToField: '',
      });
    };
    const removeFieldMapping = (fieldMapping) => {
      fieldMappings.value.splice(
        fieldMappings.value.findIndex((item) => item.id === fieldMapping.id),
        1,
      );
    };
    const textUpperCase = 'text-uppercase';
    const fieldMappingHeaders = computed(() => {
      return [
        {
          text: 'Degura field',
          value: 'deguraField',
          sortable: false,
          class: textUpperCase,
        },
        {
          text: 'Map to company field',
          value: 'mapToField',
          sortable: false,
          class: textUpperCase,
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
          class: textUpperCase,
        },
      ];
    });

    const valueMappings = ref([]);
    const addValueMapping = () => {
      valueMappings.value.push({
        id: valueMappings.value.length,
        deguraFieldValue: '',
        mapToField: '',
        mapToFieldValue: '',
      });
    };
    const removeValueMapping = (valueMapping) => {
      valueMappings.value.splice(
        valueMappings.value.findIndex((item) => item.id === valueMapping.id),
        1,
      );
    };
    const valueMappingHeaders = computed(() => {
      return [
        {
          text: 'Degura field-value(s)',
          value: 'deguraFieldValue',
          sortable: false,
          class: textUpperCase,
        },
        {
          text: 'Company field',
          value: 'mapToField',
          sortable: false,
          class: textUpperCase,
        },
        {
          text: 'Map to company field-value(s)',
          value: 'mapToFieldValue',
          sortable: false,
          class: textUpperCase,
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
          class: textUpperCase,
        },
      ];
    });

    const filters = ref([]);
    const addFilter = () => {
      filters.value.push({
        id: filters.value.length,
        companyField: '',
        logicOperator: '',
        filterValue: '',
      });
    };
    const removeFilter = (filter) => {
      filters.value.splice(
        filters.value.findIndex((item) => item.id === filter.id),
        1,
      );
    };
    const resetFilters = () => {
      filters.value = [];
    };
    const filterHeaders = computed(() => {
      return [
        {
          text: 'Filter of company',
          value: 'companyField',
          sortable: false,
          class: textUpperCase,
        },
        {
          text: 'Logic',
          value: 'logicOperator',
          sortable: false,
          class: textUpperCase,
        },
        {
          text: 'Value',
          value: 'filterValue',
          sortable: false,
          class: textUpperCase,
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
          class: textUpperCase,
        },
      ];
    });

    const logicOperatorOptions = [
      {
        text: '=',
        value: 'ne',
      },
      {
        text: '!=',
        value: 'eq',
      },
    ];

    const getDataFromPersonio = () => {
      getPersonioCompanyById(vm.$route.params.id).then((res) => {
        companyIsPersonioConnected.value = true; // This endpoint returns data from our db, so creds don't have to be valid
        dataImportProcess.value = DATA_IMPORT_PERSONIO;
        fieldMappings.value = [];
        for (const [degField, comField] of Object.entries(res.data.configJson.targetToSourceFieldMapping)) {
          fieldMappings.value.push({
            deguraField: degField,
            mapToField: comField,
          });
        }

        valueMappings.value = [];
        for (const [degField, smappings] of Object.entries(res.data.configJson.fieldEnumMapping)) {
          for (const [key, value] of Object.entries(smappings)) {
            valueMappings.value.push({
              deguraFieldValue: degField,
              mapToField: key,
              mapToFieldValue: value,
            });
          }
        }

        filters.value = res.data.configJson.filterConfig.map((sfilter) => ({
          companyField: sfilter.field,
          logicOperator: sfilter.operation,
          filterValue: sfilter.value,
        }));
      }); // TODO: BE raises err code 500 when company isn't found - not only does this show on FE; it also means we can't check for actual failure.

      getPersonioAttributes(vm.$route.params.id)
        .then((res) => {
          companyHasValidPersonioCreds.value = true; // We got data from Personio, which means credentials are working
          attributes.value = [];
          res.data.data.forEach((item) => {
            item.key.includes('dynamic') ? attributes.value.push(item.label) : attributes.value.push(item.key);
          });
        })
        .catch((err) => {
          companyHasValidPersonioCreds.value = false; // Credentials are probably wrong - no way of knowing for sure currently
        });
    };
    getDataFromPersonio();

    return {
      isFormValid,
      dataImportForm,
      onSync,

      dryRun,
      isDryRunning,
      dryRunResults,
      isSyncRunning,

      personioID,
      personioSecret,
      resetPersonioCredentials,
      setPersonioCredentials,
      companyIsPersonioConnected,
      companyHasValidPersonioCreds,

      dataImportOptions,
      dataImportProcess,

      fieldMappings,
      addFieldMapping,
      removeFieldMapping,
      fieldMappingHeaders,

      valueMappings,
      addValueMapping,
      removeValueMapping,
      valueMappingHeaders,

      filters,
      addFilter,
      removeFilter,
      resetFilters,
      filterHeaders,

      DATA_IMPORT_MANUAL,
      DATA_IMPORT_PERSONIO,
      logicOperatorOptions,
      attributes,

      icons: {
        mdiMinusCircle,
      },

      validators: {
        required,
      },
    };
  },
  props: {
    companyData: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.flex-button {
  flex-basis: 50%;
}
.multiline-label {
  line-height: 1.5rem;
}
.previewPlaceholder {
  padding: 3rem 0;
  font-size: 14px;
  background-color: #f3f3f3;
  color: rgb(137, 137, 137);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
